/* C L A S S */

/* status */
.status {
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 100;

    pointer-events: none;
    user-select: none;
}
.status ul {
    mask: linear-gradient(90deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    width: 180px;

    list-style-type: none;
    overflow: hidden;

    margin: 0;
    padding: 0 0 8px;
}
.status ul:before {
    content: attr(aria-label);
    font-weight: 700;
    white-space: nowrap;
}
.status ul li {
    white-space: nowrap;
}
.status ul li figure {
    border-radius: 50%;
    width: 8px;
    height: 8px;

    display: inline-block;

    margin: 0;
    position: relative;
    top: 2px;
}