/* C L A S S */

/* editor */
.editor {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 60px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

/* editor-break */
.editor-break {
    width: 100%;

    order: 4;
}

/* editor-form, editor-list */
.editor-form,
.editor-list {
    width: calc(50vw - 30px);
    max-width: 360px;
    min-width: 240px;
    height: calc(50vw - 30px);
    max-height: 360px;
    min-height: 240px;

    margin: 0 10px;
    position: relative;
}

/* editor-form */
.editor-form {
    order: 1;

    overflow: hidden;
    pointer-events: none;
}
.editor-form div,
.editor-form figure,
.editor-form form {
    width: 100%;
    height: 100%;
    
    position: absolute;
    top: 0;
    left: 0;
}
.editor-form div {
    z-index: -2;
}
.editor-form figure {
    margin: 0;
    padding: 0;

    pointer-events: all;
}
.editor-form form textarea {
    text-align: center;
    white-space: break-spaces;
    word-wrap: break-word;

    background: transparent;
    border: none;
    outline: none;
    width: 85%;

    overflow: hidden;
    resize: none;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    pointer-events: all;
}

/* editor-list */
.editor-list {
    order: 3;

    overflow-y: auto;
}
.editor-list::-webkit-scrollbar {
    width: 28px;
}
.editor-list::-webkit-scrollbar-track {
    margin: 20px 0;
}
.editor-list button figure,
.editor-list ul li figure {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;

    transition: height 0.05s, box-shadow 0.05s;
}
.editor-list button:hover figure,
.editor-list ul li:hover figure {
    height: calc(100% - 8px);
}
.editor-list button:active figure,
.editor-list ul li:active figure {
    height: calc(100% - 10px);
}
.editor-list button figure.overlay,
.editor-list ul li figure.overlay {
    z-index: 100;
}
.editor-list button span,
.editor-list ul li span {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    
    transition: top 0.05s;
}
.editor-list button:hover span,
.editor-list ul li:hover span {
    top: calc(45% + 8px);
}
.editor-list button:active span,
.editor-list ul li:active span {
    top: calc(45% + 10px);
}
.editor-list button {
    font-size: 24px;

    background: transparent;
    border: none;
    width: 180px;
    height: 80px;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: hand;
    cursor: pointer;
}
.editor-list ul {
    width: calc(100% - 40px);

    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 20px;
    row-gap: 20px;
    list-style-type: none;

    margin: 20px 0 20px 20px;
    padding: 0;
}
.editor-list ul li {
    font-size: 48px;

    width: 100%;

    padding-top: 100%;
    position: relative;
    vertical-align: top;

    cursor: hand;
    cursor: pointer;
    user-select: none;
}

/* editor-menu */
.editor-menu {
    order: 5;
}
.editor-menu ul {
    width: 340px;

    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 10px;
    list-style-type: none;

    margin: 20px 0 0;
    padding: 0;
}
.editor-menu ul li {
    background: #ffffff;
    width: 100%;

    padding-top: 100%;
    position: relative;

    cursor: hand;
    cursor: pointer;
    user-select: none;
}
.editor-menu ul li span {
    font-size: 24px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* R E S P O N S I V E */

@media (max-width: 540px) {
    .editor {
        width: auto;

        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .editor-break {
        width: 0;
    }
    .editor-form,
    .editor-list {
        width: calc(100vw - 40px);
        min-width: 0;
        min-height: 0;

        margin: 0;

        transition: height 0.2s;
    }
    .editor-menu {
        order: 2;
    }
    .editor-menu ul {
        width: calc(100vw - 40px);
        max-width: 360px;

        margin: 10px 0;
    }
}

@media (max-width: 540px), (max-height: 560px) {
    .editor {
        top: 0px;
        transform: translate(-50%, 0);
    }
    .editor-list::-webkit-scrollbar {
        width: 18px;
    }
    .editor-list::-webkit-scrollbar-track {
        margin: 10px 0;
    }
    .editor-list ul {
        width: calc(100% - 20px);

        column-gap: 10px;
        row-gap: 10px;

        margin: 10px 0 10px 10px;
    }
}

@media (min-width: 780px) and (max-height: 560px) {
    .editor-form,
    .editor-list {
        width: calc(100vh - 200px);
        height: calc(100vh - 200px);
    }
}