/* E L E M E N T */

/* body */
body {
    color: #333343;
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;

    background: #d1dff4;
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}