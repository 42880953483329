/* C L A S S */

/* mirror */
.mirror {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 10px;

    position: absolute;
    top: -9999px;
    left: -9999px;

    pointer-events: none;
    user-select: none;
}
.mirror > div {
    display: inline-block;

    position: relative;
}
.mirror > div figure {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
}
.mirror > div p {
    text-align: center;
    white-space: break-spaces;
    word-wrap: break-word;

    width: 85%;

    overflow: hidden;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}