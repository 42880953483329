/* C L A S S */

/* app */
.app {
    width: 100vw;
    min-height: 100vh;
    
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.app > figure {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
}